import * as React from "react"

const IndexPage = () => {
  return (
    <>
      Marketing content
    </>
  )
}

export default IndexPage
